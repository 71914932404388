<template>
	<div class="summary-card" data-table="SUMMARY">
		<div>{{fitem.subject.short_summary||"无简介内容"}}</div>
		<hr />
		<div>{{fitem.comment||"无个人点评"}}</div>
	</div>
</template>

<script>
import {formatDate} from '../../utils/tools.js'
export default {
  props:['fitem'],
  name: 'summary_card',
  data () {
    return {
		time:123,
    }
  },
	created(){
		
		this.time = formatDate(this.fitem.time)
	},
  methods:{
	  getEventBus(){
		  
	  },
	  intoAnime(){
		window.open("https://bgm.tv/subject/"+this.fitem.subject.id);
	  }
  },
}
</script>

<style scoped>
.summary-card{
	width:240px;
	position:fixed;
	opacity: 0;
	z-index:-1;
	font-size: 14px;
	line-height: 20px;;
	box-sizing: border-box;
	padding: 8px;
    box-shadow: #00000055 5px 0px 10px;
    background-color: var(--background);
    color: var(--fontColor);
	backdrop-filter: saturate(2) blur(10px);
	border-radius: 10px;
    text-align: left;
	transition: .5s;
}
.summary-card.show{
	opacity: 1;
	z-index: 5;
	transition: .5s;
}
.summary-card:hover{
	opacity: 1 !important;
	z-index: 5 !important;
	transition: .5s;
}
</style>
